<template>
<div>
    <!-- My Information -->
    <div class="my-info mt-8">
        <div class="vx-row justify-center">
            <div class="vx-col lg:w-2/3 xl:w-1/2 w-full">
                <vx-card>
                    <div class="profile-icon">
                        <span class="main-icon">
                            <img src="../../assets/images/profile.svg" />
                        </span>
                        <div class="card-title flex-wrap justify-center">
                            <h2 class="text-white">{{ LabelConstant.headingLabelMyInformation }}</h2>
                            <!-- <vs-button title="Help" @click="popupActive=true" type="filled" icon-pack="feather" icon="icon-help-circle"></vs-button> -->
                        </div>
                    </div>
                    <div class="update-info-form">
                        <template>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border :label="LabelConstant.inputLabelFirstName" v-model="firstName" name="firstName" v-validate="'required'" :disabled='vendastaIsPartner'/>
                                    <span class="text-danger text-sm">{{ errors.first('firstName') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border :label="LabelConstant.inputLabelLastName" name="lastName" v-model="lastName" v-validate="'required'" :disabled='vendastaIsPartner'/>
                                    <span class="text-danger text-sm">{{ errors.first('lastName') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-input type="email" class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border :label="LabelConstant.inputLabelEmail" v-model="email" :disabled="true" />
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" icon-no-border :label="LabelConstant.inputLabelPhone" name="phone" v-model="phone" v-validate="'required'" :disabled='vendastaIsPartner' />
                                    <span class="text-danger text-sm">{{ errors.first('phone') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <span class="vs-input--label">{{ LabelConstant.inputLabelTimeZone }}</span>
                                    <v-select v-model="timeZone" :options="timeZoneoptions" name="timeZone" :reduce="timeZoneoption => timeZoneoption.Key" label="Value" :clearable="false" />
                                </div>
                            </div>
                            <div class="vx-row">
                                <div class="vx-col w-full mt-6">
                                    <vs-button type="filled" color="primary" class="mr-4 mb-2 small-btn" @click.prevent="updateInfo()">{{ LabelConstant.buttonLabelUpdateMyInformation }}</vs-button>
                                    <vs-button color="grey" type="border" class="mb-0 md:mb-2 small-btn grey-btn" @click.prevent="getMyInfo()">{{ LabelConstant.buttonLabelCancelMyChange }}</vs-button>
                                </div>
                            </div>
                        </template>
                    </div>
                </vx-card>
            </div>
        </div>
    </div>
    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import HelpModal from "../../components/modal/HelpModal";
import vSelect from "vue-select";
import {
    Validator
} from "vee-validate";
const dict = {
    custom: {
        firstName: {
            required: "First name must be more than 1 and less than 300 characters."
        },
        lastName: {
            required: "Last name must be more than 1 and less than 300 characters."
        },
        phone: {
            required: "Please enter a valid phone number."
        }
    }
};

Validator.localize("en", dict);
export default {
    components: {
        VxCard,
        HelpModal,
        vSelect
    },
    data() {
        return {
            /* My information */
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            infoId: null,
            /* Popup */
            popupActive: false,
            /* Select */
            timeZone: null,
            timeZoneoptions: [],

            vendastaIsPartner: false,
        };
    },
    created() {
        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        this.vendastaIsPartner=token[uid].vendastaIsPartner
        this.getMyInfo();
        this.getTimeZones();
    },
    methods: {
        /* Popup Close */
        closepopup() {
            this.popupActive = false;
        },
        // get time zone list 
        async getTimeZones() {
            this.$vs.loading();
            await this.axios
                .get("/ws/MyInfo/GetTimeZones")
                .then(response => {
                    let data = response.data;
                    this.timeZoneoptions = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get my information
        async getMyInfo() {
            this.$vs.loading();
            await this.axios
                .get("/ws/MyInfo/GetMyInfo")
                .then(response => {
                    let data = response.data;
                    this.firstName = data.First_Name;
                    this.lastName = data.Last_Name;
                    this.email = data.Email;
                    this.phone = data.Phone;
                    this.timeZone = data.Time_Zone_Id;
                    this.infoId = data.Id;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // update my information
        async updateInfo() {
            this.$validator.validateAll().then(async result => {
                if(result){
                    this.$vs.loading();
                    let updateInfoData = {
                        Id: this.infoId,
                        First_Name: this.firstName,
                        Last_Name: this.lastName,
                        Email: this.email,
                        Phone: this.phone,
                        Time_Zone_Id: this.timeZone
                    };
                    await this.axios
                        .post("/ws/MyInfo/UpdateMyInfo", updateInfoData)
                        .then(response => {
                            let data = response.data;
                            this.firstName = data.First_Name;
                            this.lastName = data.Last_Name;
                            this.email = data.Email;
                            this.phone = data.Phone;
                            this.timeZone = data.Time_Zone_Id;
                            this.infoId = data.Id;
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: "Success",
                                text: "Your information has been updated successfully.",
                                color: "success",
                                iconPack: "feather",
                                icon: "icon-check",
                                position: "top-right",
                                time: 4000
                            });
                        })
                        .catch(e => {
                            this.$vs.loading.close();
                            this.showError(e);
                        });
                }
            });
        }
    }
};
</script>
